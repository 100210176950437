var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
export const coreCodeSetKeys = [
    'states',
    'coverages',
    'causeCodes',
    'contact-types',
    'natureOfInjuries',
    'claim-close-reasons',
    'claim-reopen-reasons',
    'bodyParts',
    'incidentTypes',
    'quoteDeniedReasons',
    'quoteDeclinedReasons',
    'documentCategories',
    'cancellationReasons',
    'cancellationTypes',
    'transaction-types'
];
const recordTypeId = 'CodeSet';
let CodeSet = class CodeSet {
    id;
    description;
    isSystemType;
    systemConfig;
    useDropDown;
    appliesToRecordTypes;
    groupDefinitions;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], CodeSet.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], CodeSet.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], CodeSet.prototype, "isSystemType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], CodeSet.prototype, "systemConfig", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], CodeSet.prototype, "useDropDown", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], CodeSet.prototype, "appliesToRecordTypes", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], CodeSet.prototype, "groupDefinitions", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], CodeSet.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], CodeSet.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], CodeSet.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], CodeSet.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], CodeSet.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], CodeSet.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], CodeSet.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], CodeSet.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], CodeSet.prototype, "deletedUTC", void 0);
CodeSet = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        hasBackingTable: true,
        displayNameSingular: 'Code Set',
        displayNamePlural: 'Code Sets',
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'description',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'isSystemType',
                filterType: FilterType.Equals,
            },
        ],
    })
], CodeSet);
export { CodeSet };
