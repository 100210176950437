var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
export var incidentStatusEnum;
(function (incidentStatusEnum) {
    incidentStatusEnum["draft"] = "draft";
    incidentStatusEnum["submitted"] = "submitted";
    incidentStatusEnum["incident-only"] = "incident-only";
    incidentStatusEnum["claim-created"] = "claim-created";
    incidentStatusEnum["cancelled"] = "cancelled";
})(incidentStatusEnum || (incidentStatusEnum = {}));
;
export const incidentStatusList = [
    { code: incidentStatusEnum.draft, description: 'Draft' },
    { code: incidentStatusEnum.submitted, description: 'Submitted' },
    { code: incidentStatusEnum['incident-only'], description: 'Incident Only' },
    { code: incidentStatusEnum['claim-created'], description: 'Claim(s) created' },
    { code: incidentStatusEnum.cancelled, description: 'Cancelled' }
];
const recordTypeId = 'Incident';
let Incident = class Incident {
    id;
    policyholderId;
    policyholder;
    incidentType;
    status;
    incidentNumber;
    lossDate;
    lossTime;
    reportDate;
    carrierId;
    carrier;
    claimsAdministratorId;
    claimsAdministrator;
    employerId;
    employer;
    jurisdiction;
    carrierClaimNumber;
    lossDescription;
    employerNotifiedDate;
    locationId;
    location;
    natureOfInjury;
    causeCode;
    //#region WC - Employee
    bodyPart;
    employeeContactId;
    employeeContact;
    lastWorkDate;
    disabilityBeginDate;
    returnToWorkDate;
    deathDate;
    initialTreatment;
    //#endregion
    witnesses;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Incident.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'PolicyHolder' })
], Incident.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder' })
], Incident.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'incidentTypes' })
], Incident.prototype, "incidentType", void 0);
__decorate([
    FieldData({
        recordTypeId, fieldType: 'codelist', codeList: incidentStatusList
    })
], Incident.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId })
], Incident.prototype, "incidentNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Incident.prototype, "lossDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'time' })
], Incident.prototype, "lossTime", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Incident.prototype, "reportDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Carrier' })
], Incident.prototype, "carrierId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Carrier' })
], Incident.prototype, "carrier", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'ClaimsAdministrator' })
], Incident.prototype, "claimsAdministratorId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'ClaimsAdministrator' })
], Incident.prototype, "claimsAdministrator", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Employer' })
], Incident.prototype, "employerId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Employer' })
], Incident.prototype, "employer", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'states' })
], Incident.prototype, "jurisdiction", void 0);
__decorate([
    FieldData({ recordTypeId })
], Incident.prototype, "carrierClaimNumber", void 0);
__decorate([
    FieldData({ recordTypeId })
], Incident.prototype, "lossDescription", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Incident.prototype, "employerNotifiedDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Location' })
], Incident.prototype, "locationId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Location' })
], Incident.prototype, "location", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'natureOfInjuries' })
], Incident.prototype, "natureOfInjury", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'causeCodes' })
], Incident.prototype, "causeCode", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'bodyParts' })
], Incident.prototype, "bodyPart", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Contact' })
], Incident.prototype, "employeeContactId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Contact', contactType: 'employee' })
], Incident.prototype, "employeeContact", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Incident.prototype, "lastWorkDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Incident.prototype, "disabilityBeginDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Incident.prototype, "returnToWorkDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Incident.prototype, "deathDate", void 0);
__decorate([
    FieldData({
        recordTypeId, fieldType: 'codelist', isList: true, jsonStorage: true, codeList: [
            { code: '0', description: 'No Medical Treatment' },
            { code: '1', description: 'Minor by Employer' },
            { code: '2', description: 'Minor Clinic/Hospital' },
            { code: '3', description: 'Emergency Care' },
            { code: '4', description: 'Hospitalized > 24 Hours' },
            { code: '5', description: 'Future Major Medical / Lost Time Anticipated' },
        ]
    })
], Incident.prototype, "initialTreatment", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Witness', isList: true, jsonStorage: true })
], Incident.prototype, "witnesses", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Incident.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Incident.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Incident.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Incident.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Incident.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Incident.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Incident.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Incident.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Incident.prototype, "deletedUTC", void 0);
Incident = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        roleCategory: RoleCategories.Incidents,
        displayNameSingular: 'Incident',
        displayNamePlural: 'Incidents',
        security: {
            useDefaultPersonaSecurity: true,
            allowCrossPolicyholderRecords: false,
            defaultToUsersRecordsOnly: true
        },
        recordHistoryConfig: {
            enable: true
        },
        workflow: {
            enabled: true,
            generalEvents: true,
            otherEvents: ['INCIDENT-SUBMITTED', 'INCIDENT-INCIDENT-ONLY', 'INCIDENT-CLAIMS-CREATED']
        },
        trailMapTypes: [TrailMapType.DataEntry, TrailMapType.Detail, TrailMapType.HomePage, TrailMapType.DataEntryStart],
        dataModelCustomization: {
            allowCustomFields: true
        },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'incidentNumber', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'status', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'incidentType', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'lossDescription', filterType: FilterType.Contains },
        ],
        lookupProperties: { resultProperties: ['incidentNumber', 'lossDate'], filterProperties: ['incidentNumber'] },
        supports: {
            docTemplates: true,
            files: true,
            views: true,
            notes: true,
            tasks: true,
            contacts: true,
            globalSearch: true
        }
    })
], Incident);
export { Incident };
