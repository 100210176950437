export function convertToFilterCondition(condition) {
    const testCondition = condition;
    if (testCondition.filterType) {
        return testCondition;
    }
    return {
        fieldId: condition.fieldId ?? '',
        fieldPath: condition.fieldRefName ?? condition.mappedObject ?? '',
        filterType: condition.filter,
        fieldRecordType: condition.fieldRecordType ?? '',
        searchParameter1: condition.searchParameter1,
        searchParameter2: condition.searchParameter2,
        searchParameter1Period: condition.searchParameter1Period,
    };
}
export function convertToFilterConditions(conditions) {
    return conditions.map((condition) => {
        return convertToFilterCondition(condition);
    });
}
export function convertToTableSchemaFieldFilter(condition) {
    return {
        fieldId: condition.fieldId,
        fieldRefName: condition.fieldPath,
        filter: condition.filterType,
        fieldRecordType: condition.fieldRecordType,
        searchParameter1: condition.searchParameter1,
        searchParameter2: condition.searchParameter2,
        searchParameter1Period: condition.searchParameter1Period,
    };
}
export function convertToTableSchemaFieldFilters(conditions) {
    return conditions.map((condition) => {
        return convertToTableSchemaFieldFilter(condition);
    });
}
export var FilterType;
(function (FilterType) {
    FilterType["After"] = "After";
    FilterType["AtorAfter"] = "AtorAfter";
    FilterType["AtorBefore"] = "AtorBefore";
    FilterType["Before"] = "Before";
    FilterType["Between"] = "Between";
    FilterType["Contains"] = "Contains";
    FilterType["ContainsAny"] = "ContainsAny";
    FilterType["ContainsAll"] = "ContainsAll";
    FilterType["DoesnotContain"] = "DoesnotContain";
    FilterType["EndsWith"] = "EndsWith";
    FilterType["Equals"] = "Equals";
    FilterType["EqualsCaseInsensitive"] = "EqualsCaseInsensitive";
    FilterType["IntheCurrent"] = "IntheCurrent";
    FilterType["IntheLast"] = "IntheLast";
    FilterType["IntheNext"] = "IntheNext";
    FilterType["IsEmpty"] = "IsEmpty";
    FilterType["IsNotEmpty"] = "IsNotEmpty";
    FilterType["IsNull"] = "IsNull";
    FilterType["IsNotNull"] = "IsNotNull";
    FilterType["LessThan"] = "LessThan";
    FilterType["MoreThan"] = "MoreThan";
    FilterType["NotEqual"] = "NotEqual";
    FilterType["StartsWith"] = "StartsWith";
    FilterType["InList"] = "InList";
    FilterType["NotInList"] = "NotInList";
})(FilterType || (FilterType = {}));
export var PrettyLogicalOperator;
(function (PrettyLogicalOperator) {
    PrettyLogicalOperator["Any"] = "Any";
    PrettyLogicalOperator["All"] = "All";
    PrettyLogicalOperator["NotAll"] = "NotAll";
    PrettyLogicalOperator["NotAny"] = "NotAny";
})(PrettyLogicalOperator || (PrettyLogicalOperator = {}));
export function convertToFilterConditionGroup(group) {
    return {
        groups: group.groups && group.groups.length > 0
            ? group.groups.map((m) => convertToFilterConditionGroup(m))
            : [],
        operator: group.operator,
        conditions: group.conditions && group.conditions.length > 0
            ? convertToFilterConditions(group.conditions)
            : [],
    };
}
export function filterGroupConditions(conditions) {
    return {
        operator: PrettyLogicalOperator.All,
        groups: [],
        conditions: conditions
    };
}
export const FilterConditionFilter = {
    IsNotDeleted: () => {
        return { fieldPath: 'deletedUTC', filterType: FilterType.IsNull };
    },
    WithId: (id) => {
        return {
            fieldPath: 'id',
            filterType: FilterType.Equals,
            searchParameter1: id,
        };
    },
    Equals: (path, value) => {
        return {
            fieldPath: path,
            filterType: FilterType.Equals,
            searchParameter1: value,
        };
    },
    InList: (path, value) => {
        return {
            fieldPath: path,
            filterType: FilterType.InList,
            searchParameter1: value,
        };
    },
    NotEquals: (path, value) => {
        return {
            fieldPath: path,
            filterType: FilterType.NotEqual,
            searchParameter1: value,
        };
    },
    IsNull: (path) => {
        return {
            fieldPath: path,
            filterType: FilterType.IsNull,
        };
    },
    IsNotNull: (path) => {
        return {
            fieldPath: path,
            filterType: FilterType.IsNotNull,
        };
    },
};
