import { CodeSet } from './record-types/code-set';
import { Contact } from './record-types/contact';
import { DataModelAdminRecordType } from './record-types/data-model-record-type';
import { AccountLedgerDeposit } from './record-types/account-ledger-deposit';
import { AccountLedgerDepositItem } from './record-types/account-ledger-deposit-item';
import { AccountingLedgerEntry } from './record-types/account-ledger-Entry';
import { Action } from './record-types/workflows/action';
import { Address } from './record-types/address';
import { Agency } from './record-types/agency';
import { AgencyCommissionSetting } from './record-types/agency-commission-setting';
import { Agent } from './record-types/agent';
import { AgentCommissionSetting } from './record-types/agent-commission-setting';
import { AppSetting } from './record-types/app-setting';
import { Applicant } from './record-types/applicant';
import { AutopayPlan } from './record-types/autopay-plan';
import { BulkActionHistory } from './record-types/bulk-action-history';
import { Carrier } from './record-types/carrier';
import { Claim } from './record-types/claims/claim';
import { ClaimFinancialBucket } from './record-types/claims/claim-financial-bucket';
import { ClaimHistory } from './record-types/claim-history';
import { ClaimPayment } from './record-types/claims/claim-payment';
import { ClaimReserve } from './record-types/claims/claim-reserve';
import { ClaimTransaction } from './record-types/claims/claim-transactions';
import { Claimant } from './record-types/claimant';
import { ClaimsAdministrator } from './record-types/claims-administrator';
import { ClassCode } from './record-types/class-code';
import { Code } from './record-types/code';
import { CommissionPayment } from './record-types/commission-payment';
import { DataImportHistory } from './record-types/data-import-history';
import { DataModelAdminField } from './record-types/data-model-field';
import { DocumentTemplate } from './record-types/document-template';
import { Employer } from './record-types/employer';
import { Endorsement } from './record-types/endorsement';
import { File } from './record-types/file';
import { Incident } from './record-types/incident';
import { IncludedExcludedIndividual } from './record-types/included-excluded-individual';
import { Integration } from './record-types/integration';
import { Invoice } from './record-types/invoice';
import { InvoiceAppliedPayment } from './record-types/invoice-applied-payment';
import { InvoiceInstallment } from './record-types/invoice-installment';
import { InvoiceLine } from './record-types/invoice-line';
import { InvoiceRuleSet } from './record-types/invoice-rule-set';
import { LedgerAccount } from './record-types/accounting/ledger-account';
import { LedgerAccountGroup } from './record-types/accounting/ledger-account-group';
import { LineItemType } from './record-types/line-item-type';
import { LinkedContact } from './record-types/linked-contact';
import { Location } from './record-types/location';
import { LossRunConfig } from './record-types/loss-run-config';
import { Message } from './record-types/message';
import { Note } from './record-types/note';
import { Notification } from './record-types/notification';
import { PayrollClassCode } from './record-types/payroll-class-code';
import { PayrollReport } from './record-types/payroll-report';
import { PayrollReportFactor } from './record-types/payroll-report-factors';
import { PayrollReportRate } from './record-types/payroll-report-rate';
import { Phone } from './record-types/phone';
import { Policy } from './record-types/policy';
import { PolicyAudit } from './record-types/policy-audit';
import { PolicyAuditLineItem } from './record-types/policy-audit-line-item';
import { PolicyBillingSchedule } from './record-types/policy-billing-schedule';
import { PolicyBillingScheduleType } from './record-types/policy-billing-schedule-type';
import { PolicyCommissionSetting } from './record-types/policy-commission-setting';
import { PolicyHolder } from './record-types/policy-holder';
import { PolicyPayment } from './record-types/policy-payment';
import { PolicyPaymentMethod } from './record-types/policy-payment-method';
import { PolicySnapshot } from './record-types/policy-snapshot';
import { PolicyholderDeposit } from './record-types/accounting/policyholder-deposit';
import { PolicyholderLedgerEntry } from './record-types/accounting/policyholder-ledger-entry';
import { Quote } from './record-types/quote';
import { QuoteCommissionSetting } from './record-types/quote-commission-setting';
import { QuotePayrollDetail } from './record-types/quote-payroll-detail';
import { QuoteSubtotalDetail } from './record-types/quote-subtotal-detail';
import { RateTable } from './record-types/rate-table';
import { RateTableEnhanced } from './record-types/rate-table-enhanced';
import { RateTableEnhancedInstance } from './record-types/rate-table-enhanced-Instance';
import { RateTableInstanceEnhancedDefinition } from './record-types/rate-table-rate-definition';
import { RatingFormula } from './record-types/rating-formula';
import { RatingFormulaVersion } from './record-types/rating-formula-version';
import { ReadReceipt } from './record-types/read-receipt';
import { RecordHistory } from './record-types/record-history';
import { Renewal } from './record-types/renewal';
import { Role } from './record-types/role';
import { ScheduledJob } from './record-types/scheduled-job';
import { Submission } from './record-types/submission';
import { SurplusCalculation } from './record-types/surplus-calculation';
import { SurplusCalculationLineItem } from './record-types/surplus-calculation-line-item';
import { Task } from './record-types/task';
import { TrailMap } from './record-types/trail-map';
import { TrailMapFill } from './record-types/trail-map-fill';
import { User } from './record-types/user';
import { View } from './record-types/view';
import { Witness } from './record-types/witness';
import { Workflow } from './record-types/workflows/workflow';
import { WorkflowRunHistory } from './record-types/workflows/workflow-run-history';
export const TENANT_ALIAS_PREFIX = '__';
const basicFieldTypeList = [
    'string',
    'email',
    'phone',
    'codelist',
    'datetime',
    'date',
    'boolean',
    'currency',
    'percentage',
    'percentage-whole-number',
    'integer',
    'decimal',
    'formula',
    'filesize',
    'time',
    'phone-number',
    'year',
];
export const basicFieldTypes = [
    { key: 'string', value: 'Text' },
    { key: 'codelist', value: 'Code List' },
    { key: 'datetime', value: 'Date w/ Time' },
    { key: 'date', value: 'Date' },
    { key: 'time', value: 'Time' },
    { key: 'boolean', value: 'Yes/No' },
    { key: 'currency', value: 'Currency' },
    { key: 'percentage', value: 'Percentage' },
    { key: 'percentage-whole-number', value: 'Percentage (whole number)' },
    { key: 'integer', value: 'Number (no decimals)' },
    { key: 'decimal', value: 'Number (w/ decimals)' },
    { key: 'formula', value: 'Formula' },
    { key: 'email', value: 'Email Address' },
    { key: 'phone-number', value: 'Phone Number' },
    { key: 'filesize', value: 'File Size' },
    { key: 'year', value: 'Year' },
];
const internalFieldTypesList = ['uuid', 'jsonb', 'mapArea', 'recordTypeLookup'];
export const DataModelRecordTypes = {
    User: User,
    PolicyHolder: PolicyHolder,
    PolicyPayment: PolicyPayment,
    Submission: Submission,
    Claim: Claim,
    Policy: Policy,
    PolicySnapShot: PolicySnapshot,
    Agency: Agency,
    Agent: Agent,
    Address: Address,
    Phone: Phone,
    Claimant: Claimant,
    ClaimFinancialBucket: ClaimFinancialBucket,
    ClaimHistory: ClaimHistory,
    ClaimPayment: ClaimPayment,
    ClaimReserve: ClaimReserve,
    ClaimTransaction: ClaimTransaction,
    CommissionPayment: CommissionPayment,
    PayrollReport: PayrollReport,
    View: View,
    ClassCode: ClassCode,
    PayrollClassCode: PayrollClassCode,
    PayrollReportRate: PayrollReportRate,
    PayrollReportFactor: PayrollReportFactor,
    RatingFormula: RatingFormula,
    RatingFormulaVersion: RatingFormulaVersion,
    Invoice: Invoice,
    InvoiceLine: InvoiceLine,
    InvoiceAppliedPayment: InvoiceAppliedPayment,
    InvoiceInstallment: InvoiceInstallment,
    LineItemType: LineItemType,
    AutopayPlan: AutopayPlan,
    Note: Note,
    Message: Message,
    Endorsement: Endorsement,
    PolicyPaymentMethod: PolicyPaymentMethod,
    Contact: Contact,
    AccountingLedgerEntry: AccountingLedgerEntry,
    AccountLedgerDeposit: AccountLedgerDeposit,
    AccountLedgerDepositItem: AccountLedgerDepositItem,
    Quote: Quote,
    QuoteSubtotalDetail: QuoteSubtotalDetail,
    QuotePayrollDetail: QuotePayrollDetail,
    Renewal: Renewal,
    PolicyholderLedgerEntry: PolicyholderLedgerEntry,
    PolicyholderDeposit: PolicyholderDeposit,
    LedgerAccount: LedgerAccount,
    LedgerAccountGroup: LedgerAccountGroup,
    Task: Task,
    TrailMap: TrailMap,
    ReadReceipt: ReadReceipt,
    DataImportHistory: DataImportHistory,
    Notification: Notification,
    RateTable: RateTable,
    PolicyBillingSchedule: PolicyBillingSchedule,
    PolicyBillingScheduleType: PolicyBillingScheduleType,
    AppSetting: AppSetting,
    Applicant: Applicant,
    RecordHistory: RecordHistory,
    DocumentTemplate: DocumentTemplate,
    LossRunConfig: LossRunConfig,
    CodeSet: CodeSet,
    Code: Code,
    File: File,
    Role: Role,
    Workflow: Workflow,
    Action: Action,
    ScheduledJob: ScheduledJob,
    WorkflowRunHistory: WorkflowRunHistory,
    IncludedExcludedIndividual: IncludedExcludedIndividual,
    Location: Location,
    DataModelRecordType: DataModelAdminRecordType,
    DataModelRecordTypeField: DataModelAdminField,
    PolicyAudit: PolicyAudit,
    PolicyAuditLineItem: PolicyAuditLineItem,
    AgencyCommissionSetting: AgencyCommissionSetting,
    AgentCommissionSetting: AgentCommissionSetting,
    PolicyCommissionSetting: PolicyCommissionSetting,
    SurplusCalculation: SurplusCalculation,
    SurplusCalculationLineItem: SurplusCalculationLineItem,
    BulkActionHistory: BulkActionHistory,
    Integration: Integration,
    QuoteCommissionSetting: QuoteCommissionSetting,
    InvoiceRuleSet: InvoiceRuleSet,
    Carrier: Carrier,
    ClaimsAdministrator: ClaimsAdministrator,
    Employer: Employer,
    Incident: Incident,
    Witness: Witness,
    TrailMapFill: TrailMapFill,
    RateTableEnhanced: RateTableEnhanced,
    RateTableEnhancedInstance: RateTableEnhancedInstance,
    RateTableInstanceEnhancedDefinition: RateTableInstanceEnhancedDefinition,
    LinkedContact: LinkedContact,
};
export const FieldHideAreaFlagsList = ['All', 'Filters', 'Columns', 'TrailMaps'];
export var FormulaFilterType;
(function (FormulaFilterType) {
    FormulaFilterType["StaticValue"] = "staticValue";
    FormulaFilterType["Join"] = "join";
})(FormulaFilterType || (FormulaFilterType = {}));
export var FunctionType;
(function (FunctionType) {
    FunctionType["Count"] = "count";
    FunctionType["Sum"] = "sum";
    FunctionType["Addition"] = "addition";
    FunctionType["Subtraction"] = "subtraction";
    FunctionType["Multiplication"] = "multiplication";
    FunctionType["Division"] = "division";
})(FunctionType || (FunctionType = {}));
export var ModelExpressionType;
(function (ModelExpressionType) {
    ModelExpressionType["Formula"] = "Formula";
    ModelExpressionType["StaticString"] = "String";
    ModelExpressionType["StringArray"] = "StringArray";
    ModelExpressionType["StaticNumber"] = "Number";
    ModelExpressionType["NumberArray"] = "NumberArray";
    ModelExpressionType["Case"] = "CaseExpression";
    ModelExpressionType["DataSet"] = "DataSet";
})(ModelExpressionType || (ModelExpressionType = {}));
//todo: turn into config
export function saveAsNestedObject(recordType) {
    return ['address', 'phone', 'payrollclasscode'].includes(recordType.toLowerCase());
}
